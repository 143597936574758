import normalizeImagePath from 'web/src/utils/normalizeImagePath';
import { useErrorsConverter } from 'web/src/modules/errors/composables';

const errorPrefix = 'Unable to load image:';
export default function loadImage(appUrl: string, imageRawSource: string): Promise<HTMLImageElement> {
  if (process.env.VUE_APP_RENDERING_SSR && !process.env.VUE_APP_STORYBOOK) {
    return Promise.reject(new Error(`${errorPrefix} ssr context is not supporting resources loading`));
  }
  try {
    const imageSource = normalizeImagePath(appUrl, imageRawSource);
    return new Promise((resolve, reject) => {
      const img = new Image();
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', () => reject(new Error(`${errorPrefix} ${imageSource}`)));
      img.src = imageSource;
    });
  } catch (error) {
    return Promise.reject(useErrorsConverter().convertToBaseError(error));
  }
}
