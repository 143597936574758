class AppEmitter {
  constructor() {
    this.connections = [];
    this.listeners = /* @__PURE__ */ new Map();
  }
  on(eventName, callback) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, []);
    }
    const currentListeners = this.listeners.get(eventName);
    currentListeners == null ? void 0 : currentListeners.push(callback);
  }
  once(eventName, callback) {
    const onceWrapper = (...args) => {
      callback(...args);
      this.off(eventName, onceWrapper);
    };
    this.on(eventName, onceWrapper);
  }
  off(eventName, callback) {
    if (eventName === void 0) {
      this.listeners.clear();
    } else if (callback) {
      const currentListeners = this.listeners.get(eventName);
      if (currentListeners) {
        this.listeners.set(eventName, currentListeners.filter((listener) => listener !== callback));
      }
    } else {
      this.listeners.delete(eventName);
    }
  }
  emit(...rest) {
    this.emitFrom([], rest[0], ...rest.slice(1));
  }
  emitFrom(sourceEmitter, eventName, ...args) {
    if (sourceEmitter.includes(this)) return;
    const currentListeners = this.listeners.get(eventName);
    if (currentListeners) {
      for (const listener of currentListeners) {
        listener(...args);
      }
    }
    for (const connection of this.connections) {
      connection.emitFrom([...sourceEmitter, this], eventName, ...args);
    }
  }
  detectCycle(node, state) {
    const index = state.indexOf(node);
    if (index !== -1 && state.length - index > 2) {
      return true;
    }
    if (index === -1) {
      state.push(node);
      for (const neighbor of node.connections) {
        if (this.detectCycle(neighbor, state)) {
          return true;
        }
      }
      state.pop();
    }
    return false;
  }
  connect(emitter) {
    if (this === emitter) throw new Error("Unable to connect an emitter to itself");
    if (this !== emitter) {
      this.connections.push(emitter);
      emitter.connections.push(this);
    }
    if (this.detectCycle(this, [])) throw new Error("Cycle has been detected");
  }
  setConnections(connections) {
    this.connections = connections;
  }
  disconnect(emitter) {
    this.setConnections(
      this.connections.filter((item) => emitter !== item)
    );
    emitter.setConnections(
      emitter.connections.filter((item) => this !== item)
    );
  }
}
export {
  AppEmitter
};
