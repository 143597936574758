var ButtonTextColor = /* @__PURE__ */ ((ButtonTextColor2) => {
  ButtonTextColor2["WHITE"] = "white";
  ButtonTextColor2["DEFAULT"] = "default";
  return ButtonTextColor2;
})(ButtonTextColor || {});
var AnchorTarget = /* @__PURE__ */ ((AnchorTarget2) => {
  AnchorTarget2["BLANK"] = "_blank";
  AnchorTarget2["SELF"] = "_self";
  AnchorTarget2["PARENT"] = "_parent";
  AnchorTarget2["TOP"] = "_top";
  return AnchorTarget2;
})(AnchorTarget || {});
var ButtonHeight = /* @__PURE__ */ ((ButtonHeight2) => {
  ButtonHeight2["X_LARGE"] = "x-large";
  ButtonHeight2["LARGE"] = "large";
  ButtonHeight2["MEDIUM"] = "medium";
  ButtonHeight2["SMALL"] = "small";
  ButtonHeight2["TINY"] = "tiny";
  ButtonHeight2["TINY_EXTRA"] = "tiny-extra";
  return ButtonHeight2;
})(ButtonHeight || {});
var ButtonKind = /* @__PURE__ */ ((ButtonKind2) => {
  ButtonKind2["PRIMARY"] = "primary";
  ButtonKind2["PRIMARY_DARK_VERSION"] = "primary-dark-version";
  ButtonKind2["PRIMARY_OPACITY"] = "primary-opacity";
  ButtonKind2["SECONDARY"] = "secondary";
  ButtonKind2["SECONDARY_DARK"] = "secondary-dark";
  ButtonKind2["BASE"] = "base";
  ButtonKind2["BASE_OPACITY"] = "base-opacity";
  ButtonKind2["TRANSPARENT"] = "transparent";
  ButtonKind2["TRANSPARENT_BLUE"] = "transparent-blue";
  ButtonKind2["YELLOW"] = "yellow";
  ButtonKind2["RED"] = "red";
  ButtonKind2["CANCEL"] = "cancel";
  ButtonKind2["NAV"] = "nav";
  ButtonKind2["HEADER"] = "header";
  ButtonKind2["HEADER_DARK_VERSION"] = "header-dark-version";
  ButtonKind2["HEADER_ACTIVE"] = "header-active";
  ButtonKind2["HEADER_ACTIVE_DARK_VERSION"] = "header-active-dark-version";
  ButtonKind2["MONOCHROME"] = "monochrome";
  ButtonKind2["DARK_OPACITY"] = "dark-opacity";
  ButtonKind2["TRANSPARENT_DARK_VERSION"] = "transparent-dark-version";
  return ButtonKind2;
})(ButtonKind || {});
var ButtonType = /* @__PURE__ */ ((ButtonType2) => {
  ButtonType2["BUTTON"] = "button";
  ButtonType2["SUBMIT"] = "submit";
  ButtonType2["RESET"] = "reset";
  return ButtonType2;
})(ButtonType || {});
export {
  AnchorTarget,
  ButtonHeight,
  ButtonKind,
  ButtonTextColor,
  ButtonType
};
