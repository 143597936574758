import { assert, isObject, isNumber } from "@leon-hub/guards";
import { useLocalStorageManager } from "@leon-hub/local-storage";
import debug from "debug";
const KEY_VALUE = "v";
const KEY_CHANGED = "c";
const KEY_EXPIRES = "e";
const name = "@leon-hub/storage";
const version = "1.0.0";
const sideEffects = false;
const type = "module";
const main = "./dist/index.umd.cjs";
const module = "./dist/index.js";
const typings = "./src/index.ts";
const files = [
  "dist"
];
const exports = {
  ".": {
    "import": "./dist/index.js",
    require: "./dist/index.umd.cjs"
  }
};
const dependencies = {
  "@leon-hub/errors": "workspace:^*",
  "@leon-hub/guards": "workspace:^*",
  "@leon-hub/local-storage": "workspace:^*",
  "@leon-hub/tsc": "workspace:^*",
  "@leon-hub/types": "workspace:^*",
  "@leon-hub/utils": "workspace:^*",
  debug: "^4.3"
};
const peerDependencies = {
  vue: "3.5.13"
};
const nx = {
  includedScripts: []
};
const pkg = {
  name,
  version,
  sideEffects,
  type,
  main,
  module,
  typings,
  files,
  exports,
  dependencies,
  peerDependencies,
  nx
};
const log = /* @__PURE__ */ debug(/* @__PURE__ */ (() => pkg.name)());
function isParsedStorageItem(item) {
  assert(isObject(item));
  assert(KEY_VALUE in item);
  assert(isNumber(item[KEY_CHANGED]));
  assert(!(KEY_EXPIRES in item) || isNumber(item[KEY_EXPIRES]));
  return true;
}
class Storage {
  constructor(options) {
    this.options = options;
    this.cache = void 0;
    try {
      const result = this.getItem();
      if (result.exists) {
        this.setCache(result.value);
      }
    } catch {
    }
  }
  getItem() {
    if (this.cache) return { exists: true, value: this.cache };
    const { id } = this.options;
    const rawValue = useLocalStorageManager().getItem(id);
    if (typeof rawValue === "string") {
      try {
        const storageItem = JSON.parse(rawValue);
        if (isParsedStorageItem(storageItem)) {
          const { [KEY_VALUE]: value } = storageItem;
          if (this.options.guard(value)) {
            return { value, exists: true };
          }
        }
      } catch (err) {
        console.warn(`Unable to parse "${id}" storage value: ${rawValue}`, err);
      }
    }
    return {
      exists: false
    };
  }
  get() {
    return Promise.resolve(this.getItem());
  }
  set(value, options = {}) {
    const { expires } = options;
    const { id } = this.options;
    log(`writing "${id}" storage value: %o using options: %o`, value, options);
    assert(this.options.guard(value), `Invalid "${id}" storage value: ${value}`);
    useLocalStorageManager().setItem(id, JSON.stringify({ [KEY_VALUE]: value, [KEY_CHANGED]: Date.now(), [KEY_EXPIRES]: expires }));
    this.setCache(value);
    return Promise.resolve();
  }
  clear() {
    this.cache = void 0;
    useLocalStorageManager().removeItem(this.options.id);
    return Promise.resolve();
  }
  setCache(value) {
    this.cache = value;
  }
}
export {
  Storage
};
