import { getQueryParams, getBuiltinConfig, getLocationHref } from "@leon-hub/service-locator-env";
import { useLocalStorageManager } from "@leon-hub/local-storage";
const SINGLE_QUOTE = "'";
const DOUBLE_QUOTE = '"';
const isSingleRootHtmlFragment = (html) => {
  let inAttr = false;
  let currentQuote = null;
  let inTag = false;
  let topLevelElements = 0;
  let depth = 0;
  for (let i = 0; i < html.length; i += 1) {
    const char = html[i];
    if (!inAttr && char === "<") {
      inTag = true;
      if (html[i + 1] === "/") {
        depth -= 1;
      } else {
        if (depth === 0) topLevelElements += 1;
        depth += 1;
      }
    } else if (inTag && !inAttr && char === ">") {
      inTag = false;
      if (html[i - 1] === "/") {
        depth -= 1;
      }
    } else if (!inTag && char.trim() && depth === 0) {
      topLevelElements += 1;
      break;
    } else if (inTag) {
      if (inAttr && currentQuote === char) {
        currentQuote = null;
        inAttr = false;
      } else if (!inAttr && (SINGLE_QUOTE === char || DOUBLE_QUOTE === char)) {
        currentQuote = char;
        inAttr = true;
      }
    }
    if (topLevelElements > 1) return false;
  }
  return topLevelElements === 1;
};
const DIRECTIVE_NAME = "auto-id";
const createAutomationDirective = ({
  elemAttrName
}) => ({
  // Directive has a mounted and updated lifecycle hook
  mounted(el, binding) {
    if (binding.value && el instanceof Element) {
      el.setAttribute(elemAttrName, `${binding.value}`);
    }
  },
  updated(el, binding) {
    if (el instanceof Element) {
      if (binding.value) {
        el.setAttribute(elemAttrName, `${binding.value}`);
      } else {
        el.removeAttribute(elemAttrName);
      }
    }
  }
});
const AutomationPlugin = {
  install(app, {
    elemAttrName,
    isEnabled = false
  }) {
    app.directive(DIRECTIVE_NAME, isEnabled ? createAutomationDirective({
      elemAttrName
    }) : {});
  }
};
const featureKey = "auto-id";
function isForbiddenByDevIp(isRemoteDevIp) {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) return false;
  if (isRemoteDevIp) return false;
  return !getBuiltinConfig().isNT;
}
const isAutomationPluginEnabled = (isRemoteDevIp) => {
  const localStorageManager = useLocalStorageManager();
  if (process.env.VUE_APP_FEATURE_AUTOMATION_ID_DISABLED) return false;
  if (!process.env.VUE_APP_PLATFORM_CORDOVA && isForbiddenByDevIp(isRemoteDevIp)) return false;
  if (process.env.VUE_APP_PLATFORM_WEB && featureKey in getQueryParams()) {
    return true;
  }
  return !!localStorageManager.getItem(featureKey);
};
const enableAutomationPlugin = () => {
  const localStorageManager = useLocalStorageManager();
  let isChanged = false;
  if (!localStorageManager.getItem(featureKey)) {
    localStorage.setItem(featureKey, "1");
    isChanged = true;
  }
  return isChanged;
};
const disableAutomationPlugin = () => {
  const localStorageManager = useLocalStorageManager();
  let isChanged = false;
  if (localStorageManager.getItem(featureKey)) {
    localStorage.removeItem(featureKey);
    isChanged = true;
  }
  if (process.env.VUE_APP_PLATFORM_WEB && featureKey in getQueryParams()) {
    const url = new URL(getLocationHref());
    url.searchParams.delete(featureKey);
    window.history.replaceState(null, "", url.toString());
    isChanged = true;
  }
  return isChanged;
};
export {
  AutomationPlugin,
  DIRECTIVE_NAME,
  disableAutomationPlugin,
  enableAutomationPlugin,
  isAutomationPluginEnabled,
  isSingleRootHtmlFragment
};
