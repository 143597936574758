import { isObject, assert, isString, isUndefined, isOptionalArray, isArray, isNumber, isBoolean, isArrayOfStrings } from "@leon-hub/guards";
function isUnknown(argument) {
  return true;
}
const isJsonSchemaOrBoolean = (argument) => {
  return !!(isBoolean(argument) || isJsonSchema(argument));
};
const isJsonSchemaOrBooleanArray = (argument) => {
  if (!isArray(argument)) return false;
  return argument.every((element) => isJsonSchemaOrBoolean(element));
};
const isJsonSchemaItems = (argument) => {
  return !!(isJsonSchemaOrBoolean(argument) || isJsonSchemaOrBooleanArray(argument));
};
const isOptionalJsonSchemaItems = (argument) => {
  return !!(isUndefined(argument) || isJsonSchemaItems(argument));
};
const isOptionalJsonSchemaOrBoolean = (argument) => {
  return !!(isUndefined(argument) || isJsonSchemaOrBoolean(argument));
};
const isJsonSchemaDependencies = (argument) => {
  if (isObject(argument)) {
    const items = Object.values(argument);
    return items.every((item) => isBoolean(item) || isArrayOfStrings(item) || isJsonSchema(item));
  }
  if (isArray(argument)) {
    return argument.every((element) => isString(element));
  }
  return false;
};
const isJsonSchemaOrBooleanObject = (argument) => {
  if (!isObject(argument)) return false;
  return Object.values(argument).every((element) => isJsonSchemaOrBoolean(element));
};
const baseJsonSchemaValidationRules = () => ({
  $comment: [isString, isUndefined],
  $ref: [isString, isUndefined],
  $id: [isString, isUndefined],
  $schema: [isString, isUndefined],
  additionalItems: [isUndefined, isJsonSchemaOrBoolean],
  additionalProperties: [isUndefined, isJsonSchemaOrBoolean],
  allOf: [isUndefined, isJsonSchemaOrBooleanArray],
  anyOf: [isUndefined, isJsonSchemaOrBooleanArray],
  const: [isUnknown],
  contains: [isUndefined, isJsonSchemaOrBoolean],
  contentEncoding: [isString, isUndefined],
  contentMediaType: [isString, isUndefined],
  default: [isUnknown],
  definitions: [isUndefined, isJsonSchemaOrBooleanObject],
  dependencies: [isUndefined, isJsonSchemaDependencies],
  description: [isString, isUndefined],
  enum: [isOptionalArray],
  examples: [isArray, isUndefined],
  exclusiveMaximum: [isNumber, isUndefined],
  exclusiveMinimum: [isNumber, isUndefined],
  format: [isString, isUndefined],
  items: [isOptionalJsonSchemaItems],
  maximum: [isNumber, isUndefined],
  maxItems: [isNumber, isUndefined],
  maxLength: [isNumber, isUndefined],
  maxProperties: [isNumber, isUndefined],
  minimum: [isNumber, isUndefined],
  minLength: [isNumber, isUndefined],
  minItems: [isNumber, isUndefined],
  minProperties: [isNumber, isUndefined],
  multipleOf: [isNumber, isUndefined],
  not: [isUndefined, isJsonSchemaOrBoolean],
  oneOf: [isUndefined, isJsonSchemaOrBooleanArray],
  pattern: [isString, isUndefined],
  patternProperties: [isUndefined, isJsonSchemaOrBooleanObject],
  properties: [isUndefined, isJsonSchemaOrBooleanObject],
  propertyNames: [isUndefined, isJsonSchemaOrBooleanObject],
  readOnly: [isBoolean, isUndefined],
  required: [isArrayOfStrings, isUndefined],
  then: [isOptionalJsonSchemaOrBoolean],
  title: [isString, isUndefined],
  type: [isString, isArrayOfStrings, isUndefined],
  uniqueItems: [isBoolean, isUndefined],
  writeOnly: [isBoolean, isUndefined],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  else: [isUndefined, isBoolean, isJsonSchema],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if: [isUndefined, isBoolean, isJsonSchema]
});
const isJsonSchema = (schema) => {
  if (!isObject(schema)) {
    return false;
  }
  if (Object.keys(schema).length === 0) {
    return true;
  }
  const rules = {
    ...baseJsonSchemaValidationRules()
  };
  const argumentKeys = Object.keys(schema);
  const rulesKeys = Object.keys(rules);
  const unknownKeys = [...rulesKeys, ...argumentKeys].filter((key) => !rulesKeys.includes(key));
  if (unknownKeys.length > 0) {
    console.warn("Unexpected JsonSchema fields:", ...unknownKeys);
    return false;
  }
  return rulesKeys.every((key) => {
    const validators = rules[key];
    const result = validators.some((validator) => validator(schema[key]));
    if (!result) {
      console.warn("Invalid JsonSchema field:", key, schema[key]);
    }
    return result;
  });
};
const isFormSchema = (schema) => {
  if (!isObject(schema)) {
    return false;
  }
  const rules = {
    ...baseJsonSchemaValidationRules(),
    properties: [isJsonSchemaOrBooleanObject]
  };
  const argumentKeys = Object.keys(schema);
  const rulesKeys = Object.keys(rules);
  const unknownKeys = [...rulesKeys, ...argumentKeys].filter((key) => !rulesKeys.includes(key));
  assert(unknownKeys.length === 0, `Unexpected FormSchemasJSON fields: ${JSON.stringify(unknownKeys)}`);
  return rulesKeys.every((key) => {
    const validators = rules[key];
    const result = validators.some((validator) => validator(schema[key]));
    if (!result) {
      console.warn("Invalid FormSchemasJSON field:", key, schema[key]);
    }
    return result;
  });
};
var FormControlType = /* @__PURE__ */ ((FormControlType2) => {
  FormControlType2["Text"] = "Text";
  FormControlType2["Email"] = "Email";
  FormControlType2["Password"] = "Password";
  FormControlType2["PasswordValidator"] = "PasswordValidator";
  FormControlType2["Select"] = "Select";
  FormControlType2["DropListSelect"] = "DropListSelect";
  FormControlType2["Phone"] = "Phone";
  FormControlType2["PhoneInput"] = "PhoneInput";
  FormControlType2["PhoneCountriesSelectorInput"] = "PhoneCountriesSelectorInput";
  FormControlType2["Checkbox"] = "Checkbox";
  FormControlType2["Date"] = "Date";
  FormControlType2["BonusCode"] = "BonusCode";
  FormControlType2["SmsCode"] = "SmsCode";
  FormControlType2["Switch"] = "Switch";
  FormControlType2["Hidden"] = "Hidden";
  FormControlType2["FileSingle"] = "FileSingle";
  FormControlType2["FileMultiple"] = "FileMultiple";
  FormControlType2["FileDragAndDrop"] = "FileDragAndDrop";
  FormControlType2["FastSum"] = "FastSum";
  FormControlType2["CreditCardSelector"] = "CreditCardSelector";
  FormControlType2["PhoneDisabledInput"] = "PhoneDisabledInput";
  FormControlType2["WalletDisabledInput"] = "WalletDisabledInput";
  FormControlType2["WebmoneyDisabledInput"] = "WebmoneyDisabledInput";
  FormControlType2["Captcha"] = "Captcha";
  FormControlType2["CaptchaV3"] = "CaptchaV3";
  FormControlType2["PhotoInput"] = "PhotoInput";
  FormControlType2["Fio"] = "Fio";
  FormControlType2["PieTimerInput"] = "PieTimerInput";
  FormControlType2["MultilineTextInput"] = "MultilineTextInput";
  FormControlType2["AutoheightTextInput"] = "AutoheightTextInput";
  FormControlType2["CardCvv"] = "CardCVV";
  FormControlType2["CardExpirationDate"] = "CardExpirationDate";
  FormControlType2["CardNumber"] = "CardNumber";
  FormControlType2["DepositBonus"] = "DepositBonus";
  FormControlType2["CurrencyInput"] = "CurrencyInput";
  FormControlType2["CardName"] = "CardName";
  FormControlType2["SuggestEmail"] = "SuggestEmail";
  FormControlType2["CPFNumber"] = "CPFnumber";
  FormControlType2["AutocompleteAddress"] = "AutocompleteAddress";
  FormControlType2["SelectWithSearch"] = "SelectWithSearch";
  FormControlType2["CurrencySelect"] = "CurrencySelect";
  FormControlType2["AsoTermsCheckbox"] = "AsoTermsCheckbox";
  FormControlType2["TermsAcceptionCheckbox"] = "TermsAcceptionCheckbox";
  FormControlType2["CryptoPaymentData"] = "CryptoPaymentData";
  return FormControlType2;
})(FormControlType || {});
var FormErrorKeyword = /* @__PURE__ */ ((FormErrorKeyword2) => {
  FormErrorKeyword2["Required"] = "required";
  FormErrorKeyword2["Pattern"] = "pattern";
  FormErrorKeyword2["MinLength"] = "minLength";
  FormErrorKeyword2["MaxLength"] = "maxLength";
  FormErrorKeyword2["If"] = "if";
  FormErrorKeyword2["MinItems"] = "minItems";
  FormErrorKeyword2["MaxItems"] = "maxItems";
  FormErrorKeyword2["Format"] = "format";
  FormErrorKeyword2["InvalidDate"] = "InvalidDate";
  FormErrorKeyword2["DateUnderMinYear"] = "DateUnderMinYear";
  FormErrorKeyword2["IsNotAnAdultStart"] = "IsNotAnAdultStart";
  FormErrorKeyword2["IsNotAnAdultEnd"] = "IsNotAnAdultEnd";
  FormErrorKeyword2["IsFileWrongFormat"] = "IsFileWrongFormat";
  FormErrorKeyword2["IsFileAlreadyExists"] = "IsFileAlreadyExists";
  FormErrorKeyword2["Type"] = "type";
  FormErrorKeyword2["Enum"] = "enum";
  FormErrorKeyword2["ForbiddenSymbol"] = "ForbiddenSymbol";
  return FormErrorKeyword2;
})(FormErrorKeyword || {});
var FormSubmitMode = /* @__PURE__ */ ((FormSubmitMode2) => {
  FormSubmitMode2["AlwaysEnabled"] = "AlwaysEnabled";
  FormSubmitMode2["DisabledByErrors"] = "DisabledByErrors";
  return FormSubmitMode2;
})(FormSubmitMode || {});
var FormFocusType = /* @__PURE__ */ ((FormFocusType2) => {
  FormFocusType2["NoFocus"] = "NoFocus";
  FormFocusType2["OnMount"] = "OnMount";
  return FormFocusType2;
})(FormFocusType || {});
var FormFieldTouchedStrategy = /* @__PURE__ */ ((FormFieldTouchedStrategy2) => {
  FormFieldTouchedStrategy2["Blur"] = "blur";
  FormFieldTouchedStrategy2["Change"] = "change";
  return FormFieldTouchedStrategy2;
})(FormFieldTouchedStrategy || {});
var FormState = /* @__PURE__ */ ((FormState2) => {
  FormState2["INITIAL"] = "initial";
  FormState2["PENDING"] = "pending";
  FormState2["SUCCESS"] = "success";
  FormState2["ERROR"] = "error";
  return FormState2;
})(FormState || {});
var FormProvidable = /* @__PURE__ */ ((FormProvidable2) => {
  FormProvidable2["FormContext"] = "formContext";
  return FormProvidable2;
})(FormProvidable || {});
export {
  FormControlType,
  FormErrorKeyword,
  FormFieldTouchedStrategy,
  FormFocusType,
  FormProvidable,
  FormState,
  FormSubmitMode,
  isFormSchema,
  isJsonSchema
};
