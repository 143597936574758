var ModalHeight = /* @__PURE__ */ ((ModalHeight2) => {
  ModalHeight2["DEFAULT"] = "default";
  ModalHeight2["TINY"] = "tiny";
  ModalHeight2["SMALL_MINUS"] = "small-minus";
  ModalHeight2["SMALL"] = "small";
  ModalHeight2["NORMAL"] = "normal";
  ModalHeight2["MEDIUM"] = "medium";
  ModalHeight2["EXTRA"] = "extra";
  ModalHeight2["LARGE"] = "large";
  ModalHeight2["MAX"] = "max";
  return ModalHeight2;
})(ModalHeight || {});
var ModalWidth = /* @__PURE__ */ ((ModalWidth2) => {
  ModalWidth2["DEFAULT"] = "default";
  ModalWidth2["SMALL_MINUS"] = "small-minus";
  ModalWidth2["SMALL"] = "small";
  ModalWidth2["MEDIUM"] = "medium";
  ModalWidth2["MEDIUM_PLUS"] = "medium-plus";
  ModalWidth2["BIG"] = "big";
  ModalWidth2["LARGE"] = "large";
  return ModalWidth2;
})(ModalWidth || {});
var OverlayType = /* @__PURE__ */ ((OverlayType2) => {
  OverlayType2["BLUR_L0"] = "blur-l0";
  OverlayType2["BLUR_L1"] = "blur-l1";
  OverlayType2["OPACITY_L0"] = "opacity-l0";
  OverlayType2["OPACITY_L1"] = "opacity-l1";
  return OverlayType2;
})(OverlayType || {});
export {
  ModalHeight,
  ModalWidth,
  OverlayType
};
