import { ref, watch, nextTick } from "vue";
import { setInputCursorAfterValue } from "@leon-hub/utils";
import { isObject, isFunction } from "@leon-hub/guards";
function useAutofocus(props) {
  const focusable = ref();
  async function focus({ forceIos, atTheEnd } = {}) {
    var _a, _b;
    if (!process.env.VUE_APP_OS_IOS || forceIos) {
      await nextTick();
      if (focusable.value) {
        if (atTheEnd && focusable.value instanceof HTMLInputElement) {
          setInputCursorAfterValue(focusable.value);
        }
        (_b = (_a = focusable.value).focus) == null ? void 0 : _b.call(_a);
      }
    }
  }
  watch(() => props.autofocus, (value) => {
    if (value) {
      void focus();
    }
  }, { immediate: true });
  return {
    focusable,
    focus
  };
}
const isFocusable = (node) => isObject(node) && "focus" in node && isFunction(node.focus);
const useFocusableElement = () => {
  const element = ref();
  const focusOnElement = () => {
    if (!process.env.VUE_APP_OS_IOS && isFocusable(element.value)) {
      element.value.focus();
    }
  };
  return {
    focus: focusOnElement,
    focusable: element
  };
};
export {
  isFocusable,
  useAutofocus,
  useFocusableElement
};
