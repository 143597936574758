var Tag = /* @__PURE__ */ ((Tag2) => {
  Tag2["DIV"] = "div";
  Tag2["SPAN"] = "span";
  Tag2["INPUT"] = "input";
  Tag2["BUTTON"] = "button";
  Tag2["A"] = "a";
  Tag2["SVG"] = "svg";
  Tag2["I"] = "i";
  Tag2["LABEL"] = "label";
  Tag2["UL"] = "ul";
  Tag2["OL"] = "ol";
  Tag2["LI"] = "li";
  Tag2["H1"] = "h1";
  Tag2["H2"] = "h2";
  Tag2["H3"] = "h3";
  Tag2["H4"] = "h4";
  Tag2["H5"] = "h5";
  Tag2["H6"] = "h6";
  Tag2["P"] = "p";
  Tag2["SECTION"] = "section";
  Tag2["ARTICLE"] = "article";
  Tag2["TEXTAREA"] = "textarea";
  Tag2["DL"] = "dl";
  Tag2["DT"] = "dt";
  Tag2["DD"] = "dd";
  Tag2["ROUTER_LINK"] = "router-link";
  return Tag2;
})(Tag || {});
const tagList = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.values(Tag));
function isTag(argument) {
  return tagList.includes(argument);
}
export {
  Tag,
  isTag,
  tagList
};
