<script lang="ts" setup>
import {
  onActivated,
  onMounted,
  ref,
} from 'vue';
import { ObserveVisibility } from 'vue3-observe-visibility';

import { bannerVersionV3 } from '@leon-hub/api-sdk';

import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import SportEventInfo from 'web/src/modules/banners/components/SportEventInfo/components/SportEventInfo.vue';
import JackpotBannerInfo from 'web/src/modules/banners/components/JackpotBannerInfo/JackpotBannerInfo.vue';

import { useVHeadlineBanner } from './composables';
import type {
  VHeadlineBannerProps,
  VHeadlineBannerEmits,
} from './types';

const props = withDefaults(defineProps<VHeadlineBannerProps>(), {});
const emits = defineEmits<VHeadlineBannerEmits>();

const vObserveVisibility = ObserveVisibility;

const isBannerFgLoaded = ref(false);
const isBannerBgLoaded = ref(false);

const {
  isVisible,
  backgroundImage,
  mainImageRef,
  wrapper,
  wrapperComponent,
  wrapperProperties,
  wrapperStyles,
  isBackgroundImageAvailable,
  backgroundImageSource,
  bgImageWidth,
  hasContentBlock,
  headlineComponent,
  isHeadlineAvailable,
  textStyles,
  isSecondaryTextAvailable,
  imagePosition,
  linkProperties,
  isFrontImageAvailable,
  isButtonAvailable,
  badgeKind,
  imageFit,
  imageStyle,
  onWrapperClick,
  visibilityChanged,
  onClick,
  onComponentMounted,
  onComponentActivated,
} = useVHeadlineBanner(props, emits);

onMounted(onComponentMounted);

onActivated(onComponentActivated);
</script>

<template>
  <component v-auto-id="'VHeadlineBanner'"
    :is="wrapperComponent"
    v-bind="wrapperProperties"
    ref="wrapper"
    v-collect:click-counter="{ promo: 'misclick'}"
    v-observe-visibility="{
      callback: visibilityChanged,
    }"
    :class="{
      [$style['headline-bn']]: true,
      [$style[`headline-bn--${bannerType}`]]: !!bannerType,
      [$style['headline-bn--hidden']]: !isVisible,
      [$style['headline-bn--no-min-height']]: noMinHeight,
      [$style['headline-bn--big-height']]: isBigHeight,
      [$style['headline-bn--sport']]: hasEventDetails,
      [$style['headline-bn--jackpot']]: isJackpot,
      [$style['headline-bn--without-text']]: !hasContentBlock,
      [$style['headline-bn--half-width']]: isHalfWidth,
      [$style['headline-bn--v3']]: !!'1' && bannerVersion === bannerVersionV3,
      [$style[`headline-bn--animation-${animation?.toLowerCase().replace(/_/g, '-')}`]]: !!animation,
    }"
    :style="wrapperStyles"
    @click.prevent="onWrapperClick"
  >
    <VBadge
      v-if="isNT"
      :class="[
        $style['headline-bn__badge'],
        $style['headline-bn__badge--nt'],
        $style['headline-bn__badge--dev-ip'],
      ]"
      :kind="badgeKind"
      label="DevIP"
    />
    <VImage
      v-if="isBackgroundImageAvailable"
      ref="backgroundImage"
      v-data-test="{ el: 'banner-image-background'}"
      :src="backgroundImageSource.src"
      :x1="backgroundImageSource.x1"
      :x2="backgroundImageSource.x2"
      :x3="backgroundImageSource.x3"
      :x1webp="backgroundImageSource.x1webp"
      :x2webp="backgroundImageSource.x2webp"
      :x3webp="backgroundImageSource.x3webp"
      :force-hide-image="forceHideImage"
      :style="{ width: bgImageWidth }"
      :object-fit="ObjectFitOption.COVER"
      :is-lazy="!process.env.VUE_APP_PRERENDER && !isVisibleImage"
      :is-relative="null && (hasContentBlock || hasEventDetails)"
      :class="{
        [$style['headline-bn__bg']]: true,
        [$style['headline-bn__bg--loaded']]: isBannerBgLoaded,
        [$style['headline-bn__bg--active']]: isActive,
      }"
      @image-loaded="isBannerBgLoaded = true"
    />
    <div
      v-if="hasContentBlock"
      :class="$style['headline-bn__content']"
    >
      <component
        :is="headlineComponent"
        v-if="isHeadlineAvailable"
        :style="textStyles"
        :class="{
          [$style['headline-bn__heading']]: true,
          [$style['headline-bn__text--no-shadow']]: isTextShadowHidden,
        }"
      >
        {{ headline }}
      </component>
      <div
        v-if="isSecondaryTextAvailable"
        :style="textStyles"
        :class="{
          [$style['headline-bn__text']]: true,
          [$style['headline-bn__text--no-shadow']]: isTextShadowHidden,
        }"
      >
        {{ secondaryText }}
      </div>
    </div>
    <a
      v-if="isButtonAvailable"
      v-data-test="{ el: 'banner-button' }"
      v-bind="linkProperties"
      :class="$style['headline-bn__button']"
      @click.stop.prevent="onClick"
    >{{ buttonText }}</a>
    <VImage
      v-if="isFrontImageAvailable"
      ref="mainImageRef"
      v-data-test="{ el: 'banner-image-main'}"
      :src="mainImage.src"
      :x1="mainImage.x1"
      :x2="mainImage.x2"
      :x3="mainImage.x3"
      :x1webp="mainImage.x1webp"
      :x2webp="mainImage.x2webp"
      :x3webp="mainImage.x3webp"
      :force-hide-image="forceHideImage"
      :object-fit="imageFit"
      :object-position="imagePosition"
      :is-lazy="!process.env.VUE_APP_PRERENDER && !isVisibleImage"
      :is-relative="null && (hasContentBlock || hasEventDetails)"
      :class="{
        [$style['headline-bn__img']]: true,
        [$style['headline-bn__img--loaded']]: isBannerFgLoaded,
        [$style['headline-bn__img--active']]: isActive,
      }"
      :style="imageStyle"
      @image-loaded="isBannerFgLoaded = true"
    />
    <template v-if="'1'">
      <SportEventInfo
        v-if="hasEventDetails && bannerId && groupId"
        :banner-id="bannerId"
        :group-id="groupId"
        :is-active="isActive"
        :banner-version="bannerVersion"
      />
    </template>
    <JackpotBannerInfo
      v-if="isJackpot && bannerId && groupId"
      :banner-id="bannerId"
      :group-id="groupId"
      :is-active="isActive"
      :content="jackpotContent"
    />
  </component>
</template>

<style module lang="scss">
@keyframes banners-move-front {
  0% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  25% {
    transform: translate3d(20px, 0, 0) rotate(0.0001deg);
  }

  50% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  75% {
    transform: translate3d(-20px, 0, 0) rotate(0.0001deg);
  }

  100% {
    transform: translateZ(0) rotate(0.0001deg);
  }
}

@keyframes banners-move-bg {
  0% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  25% {
    transform: translate3d(-20px, 0, 0) rotate(0.0001deg);
  }

  50% {
    transform: translateZ(0) rotate(0.0001deg);
  }

  75% {
    transform: translate3d(20px, 0, 0) rotate(0.0001deg);
  }

  100% {
    transform: translateZ(0) rotate(0.0001deg);
  }
}

@include for-layout using($isDesktop) {
  .headline-bn {
    $self: &;

    position: relative;
    display: flex;
    width: 100%;
    min-height: if($isDesktop, 232px, 210px);
    max-height: 232px;
    overflow: hidden;
    border-radius: $headlineBannerBorderRadius;

    @if not $isDesktop {
      @media screen and (min-width: 375px) {
        min-height: 232px;
      }
    }

    @include for-hover {
      &:hover {
        cursor: pointer;
      }
    }

    &--no-min-height {
      min-height: auto;
    }

    &__content {
      position: absolute;
      top: 24px;
      right: 30%;
      left: if($isDesktop, 32px, 16px);
      z-index: z-index(banner-content);
      width: auto;
    }

    &__heading,
    &__text {
      color: var(--White);
    }

    &__heading {
      @include font-headline-banner-title($isDesktop);
    }

    &__text {
      @include font-headline-banner-text($isDesktop);
    }

    &__bg,
    &__img {
      @include headlineBannerImages;

      display: block;
      width: 100%;
      pointer-events: none;
      user-select: none;
      opacity: 0;
      transition: opacity 100ms ease-out;

      @if $isDesktop {
        @include headlineBannerImagesDesktop;
      }

      &--loaded {
        opacity: 1;
      }
    }

    &__bg {
      bottom: 0;
      max-width: none;
      transform: translateZ(0);
    }

    &__img {
      z-index: z-index(banner-img);
      transform: translateZ(0);
    }

    &__button {
      @include medium\14\16\025\caps;
      @include headlineBannerButton($isDesktop);

      position: absolute;
      z-index: z-index(banner-content);
      padding: if($isDesktop, $headlineBannerButtonPaddingDesktop, $headlineBannerButtonPaddingPhone);
      color: var(--Black);
      text-decoration: none;
      background-color: var(--White);
      border-radius: 5px;
    }

    &__badge {
      @include z-index(base);

      position: absolute;
      top: 4px;
      right: 4px;

      &--nt {
        background: var(--ErrorDefault);
      }

      &--dev-ip {
        height: 32px;
        font-size: 27px;
        line-height: 27px;
      }
    }

    &--aside-banner {
      min-height: 192px;
      margin: 8px 0;

      .headline-bn__content,
      .headline-bn__button {
        @if $isDesktop {
          left: 16px;
        }
      }

      .headline-bn__content {
        top: 16px;

        @if $isDesktop {
          max-width: 180px;
        }
      }

      .headline-bn__heading {
        @include font-aside-banner-title;

        @if $isDesktop {
          font-size: 18px;
          letter-spacing: 0.54px;
        }
      }

      .headline-bn__text {
        @include font-aside-banner-text;

        @if $isDesktop {
          font-size: 14px;
          letter-spacing: normal;
        }
      }

      .headline-bn__button {
        bottom: 16px;
        padding: $headlineBannerAsideButtonPadding;
      }

      .headline-bn__bg {
        object-position: 78% 50%;
      }

      .headline-bn__img {
        right: 0;
        object-position: right bottom;
      }
    }

    &--inline-banner {
      min-height: 76px;
      max-height: 76px;

      .headline-bn__heading {
        margin-top: 16px;
        margin-bottom: 0;
      }

      .headline-bn__button {
        top: 16px;
        right: if($isDesktop, 16px, 8px);
        bottom: 16px;
        left: auto;
      }

      .headline-bn__content {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        text-align: center;
      }

      .headline-bn__img {
        top: 16px;
        right: auto;
        bottom: 16px;
        left: if($isDesktop, 16px, 8px);
        height: auto;
      }
    }

    &--main-banner {
      .headline-bn__bg {
        object-position: top left;
      }

      .headline-bn__img {
        object-position: right bottom;

        @if $isDesktop {
          right: 16%;
          left: auto;
        }
      }

      &.headline-bn--big-height {
        .headline-bn__bg {
          object-position: 50% 50%;
        }

        .headline-bn__img {
          max-width: none;
          object-position: center bottom;
          transform: translateZ(0);

          @if $isDesktop {
            right: 0;
            left: auto;
          }
        }
      }
    }

    &--sport {
      .headline-bn__bg {
        height: auto;
        object-position: 50% 50%;
      }
    }

    &--jackpot {
      min-height: if($isDesktop, 238px, 238px);
    }

    &--big-height {
      min-height: 395px;
      border-radius: 15px;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        background:
          if(
            $isDesktop,
            linear-gradient(90deg, rgba(0, 0, 0, 0.5) 2.27%, rgba(0, 0, 0, 0) 94.54%),
            linear-gradient(-180deg, rgba(0, 0, 0, 0.3) 29.94%, rgba(0, 0, 0, 0) 77.21%)
          );
      }

      &.headline-bn--animation-move-horizontally .headline-bn__img {
        right: -20px;
        left: -20px;
      }

      .headline-bn__heading {
        margin-top: 0;
        margin-bottom: if($isDesktop, 12px, 16px);
        font-size: if($isDesktop, 36px, 24px);
        font-weight: 900;
        line-height: if($isDesktop, 40px, 28px);
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
      }

      .headline-bn__text {
        text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);

        @if $isDesktop {
          @include medium\24\28;
        } @else {
          @include medium\16\20\025;
        }
      }

      .headline-bn__button {
        bottom: 32px;
        left: if($isDesktop, 55px, 50%);
        margin: 0 auto;
        border-radius: 12px;
        box-shadow: none;
        transform: if($isDesktop, translate(0, 0), translate(-50%, 0));

        @if $isDesktop {
          top: inherit;
          bottom: 48px;
        }
      }

      .headline-bn__content {
        top: if($isDesktop, inherit, 28px);
        left: if($isDesktop, 55px, 24px);
        max-width: if($isDesktop, 550px, calc(100% - 48px));

        @if $isDesktop {
          bottom: 124px;
        } @else {
          width: 100%;
          text-align: center;
        }
      }
    }

    &--v3 {
      min-height: if($isDesktop, $headlineBannerDesktopMinHeight, 238px);

      .headline-bn__content {
        @include headlineBannerContent($isDesktop);
      }

      &:not(.headline-bn--without-text) {
        .headline-bn__button {
          @include headlineBannerButtonV2($isDesktop);
        }
      }

      &.headline-bn--without-text {
        max-height: 232px;

        @if $isDesktop {
          min-height: 197px;
        }
      }

      &.headline-bn--sport {
        @if $isDesktop {
          min-height: $headlineBannerDesktopMinHeight;
        }

        @if not ($isDesktop) {
          min-height: 238px;
        }
      }

      &.headline-bn--aside-banner.headline-bn--without-text {
        @if $isDesktop {
          min-height: 192px;
        }
      }

      &.headline-bn--aside-banner:not(&.headline-bn--without-text) {
        @if $isDesktop {
          min-height: 250px;
        }
      }

      &:not(&.headline-bn--big-height, &.headline-bn--without-text),
      &.headline-bn--sport {
        @if not $isDesktop {
          @media screen and (min-width: 360px) {
            min-height: 270px;
          }

          @media screen and (min-width: 375px) {
            min-height: 280px;
          }

          @media screen and (min-width: 412px) {
            min-height: 310px;
          }

          @media screen and (min-width: 480px) {
            min-height: 364px;
          }
        }
      }
    }

    &--half-width {
      @if $isDesktop {
        .headline-bn__heading {
          font-size: 30px;
          letter-spacing: 0.9px;
        }

        .headline-bn__text {
          font-size: 22px;
          letter-spacing: 0.66px;
        }
      }
    }

    &--animation-move-horizontally {
      .headline-bn__img,
      .headline-bn__bg {
        animation-duration: 30s;
        animation-play-state: paused;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      .headline-bn__bg {
        right: -20px;
        left: -20px;
        animation-name: banners-move-bg;
      }

      .headline-bn__img {
        animation-name: banners-move-front;
      }

      .headline-bn__img--active,
      .headline-bn__bg--active {
        animation-play-state: running;
      }

      &:hover {
        .headline-bn__img,
        .headline-bn__bg {
          animation-play-state: running;
        }
      }
    }

    &--hidden {
      .headline-bn__img,
      .headline-bn__bg {
        animation-name: none;
      }
    }
  }

  a.headline-bn {
    cursor: pointer;
  }
}

@include for-browser-safari {
  .headline-bn {
    @include z-index(base);
  }
}
</style>
