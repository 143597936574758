import createLogger from "debug";
import { AppEmitter } from "@leon-hub/app-emitter";
import { logger } from "@leon-hub/logging";
import { normalizeError } from "@leon-hub/errors";
import { onBeforeMount, onActivated, onBeforeUnmount, onDeactivated } from "vue";
import noop from "lodash/noop";
const _BusEvent = class _BusEvent {
  constructor(key) {
    this.key = key;
  }
  toString() {
    return this.key;
  }
};
_BusEvent.LAYOUT_CONTENT_SCROLL = /* @__PURE__ */ new _BusEvent("LAYOUT_CONTENT_SCROLL");
_BusEvent.LAYOUT_CONTENT_SET_SCROLL = /* @__PURE__ */ new _BusEvent("LAYOUT_CONTENT_SET_SCROLL");
_BusEvent.LAYOUT_CONTENT_SCROLL_TOP = /* @__PURE__ */ new _BusEvent("LAYOUT_CONTENT_SCROLL_TOP");
_BusEvent.MODAL_CONTENT_SCROLL_TOP = /* @__PURE__ */ new _BusEvent("MODAL_CONTENT_SCROLL_TOP");
_BusEvent.MODAL_CONTENT_SCROLL = /* @__PURE__ */ new _BusEvent("MODAL_CONTENT_SCROLL");
_BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE = /* @__PURE__ */ new _BusEvent("LAYOUT_CONTENT_SCROLL_DISABLE");
_BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE = /* @__PURE__ */ new _BusEvent("LAYOUT_CONTENT_SCROLL_ENABLE");
_BusEvent.OPEN_CHAT = /* @__PURE__ */ new _BusEvent("OPEN_CHAT");
_BusEvent.SIDE_MENU_TOGGLE = /* @__PURE__ */ new _BusEvent("SIDE_MENU_TOGGLE");
_BusEvent.SIDE_MENU_SOFT_CLOSE = /* @__PURE__ */ new _BusEvent("SIDE_MENU_SOFT_CLOSE");
_BusEvent.SPORT_EVENTS_STATUS_CHANGED = /* @__PURE__ */ new _BusEvent("SPORT_EVENTS_STATUS_CHANGED");
_BusEvent.SPORT_EVENTS_RUNNERS_CHANGED = /* @__PURE__ */ new _BusEvent("SPORT_EVENTS_RUNNERS_CHANGED");
_BusEvent.NEW_BET_HAS_BEEN_PLACED = /* @__PURE__ */ new _BusEvent("NEW_BET_HAS_BEEN_PLACED");
_BusEvent.SHOW_SLIP_MODAL_WARNING = /* @__PURE__ */ new _BusEvent("SHOW_SLIP_MODAL_WARNING");
_BusEvent.CLAIM_TO_SLIP_CLOSE = /* @__PURE__ */ new _BusEvent("CLAIM_TO_SLIP_CLOSE");
_BusEvent.SLIP_SELECTION_CHANGE = /* @__PURE__ */ new _BusEvent("SLIP_SELECTION_CHANGE");
_BusEvent.SLIP_ENTRY_DELETE_CLICK = /* @__PURE__ */ new _BusEvent("SLIP_ENTRY_DELETE_CLICK");
_BusEvent.API_ERROR = /* @__PURE__ */ new _BusEvent("API_ERROR");
_BusEvent.CAPTCHA_VERIFICATION = /* @__PURE__ */ new _BusEvent("CAPTCHA_VERIFICATION");
_BusEvent.CAPTCHA_SERVICE_ERROR = /* @__PURE__ */ new _BusEvent("CAPTCHA_SERVICE_ERROR");
_BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED = /* @__PURE__ */ new _BusEvent("CAPTCHA_CHALLENGE_IS_CLOSED");
_BusEvent.CATALOG_CLICK = /* @__PURE__ */ new _BusEvent("CATALOG_CLICK");
_BusEvent.SCROLL_TO_ELEMENT_ID = /* @__PURE__ */ new _BusEvent("SCROLL_TO_ELEMENT_ID");
_BusEvent.MODAL_SCROLL_TO_ELEMENT = /* @__PURE__ */ new _BusEvent("MODAL_SCROLL_TO_ELEMENT");
_BusEvent.QA_SCROLLBAR_SCROLL_TO = /* @__PURE__ */ new _BusEvent("QA_SCROLLBAR_SCROLL_TO");
_BusEvent.REDIRECT = /* @__PURE__ */ new _BusEvent("REDIRECT");
_BusEvent.DEPOSIT_BONUS_WIDGET_OPEN_LIST = /* @__PURE__ */ new _BusEvent("DEPOSIT_BONUS_WIDGET_OPEN_LIST");
let BusEvent = _BusEvent;
class EventsBus {
  constructor() {
    this.logPrefix = "[event-bus]";
    this.initialized = false;
    this.appEmitter = new AppEmitter();
    this.queue = [];
    this.logger = createLogger("events-bus");
  }
  get debug() {
    return this.logger.enabled;
  }
  initialize() {
    if (!this.initialized) {
      this.log("initialization");
      this.initialized = true;
      this.runQueue();
    }
  }
  getEmitter() {
    return this.appEmitter;
  }
  off(event, callback) {
    let events = [];
    if (event) {
      events = Array.isArray(event) ? event : [event];
    }
    if (this.initialized) {
      if (events.length) {
        this.removeEventsByEvent(events, callback);
      } else {
        throw new Error("Removing all events is not allowed");
      }
    } else {
      this.addQueue({ type: "off", value: [events, callback] });
    }
  }
  on(event, callback) {
    const events = Array.isArray(event) ? event : [event];
    if (this.initialized) {
      for (const item of events) {
        this.log(`starting event listener for event "${item}" at ${new Date(Date.now()).toISOString()}`);
        this.appEmitter.on(String(item), callback);
      }
    } else {
      this.addQueue({ type: "on", value: [events, callback] });
    }
  }
  once(event, callback) {
    const events = Array.isArray(event) ? event : [event];
    if (this.initialized) {
      for (const item of events) {
        this.log(`starting one time listener for event "${item}" at ${new Date(Date.now()).toISOString()}`);
        this.appEmitter.once(`${item}`, callback);
      }
    } else {
      this.addQueue({ type: "once", value: [events, callback] });
    }
  }
  emit(event, payload) {
    if (this.initialized) {
      if (this.debug) {
        this.log(
          `emitting event "${event}" with payload:
          ${JSON.stringify(payload)} at ${new Date(Date.now()).toISOString()}`
        );
      }
      this.appEmitter.emit(`${event}`, payload);
    } else {
      this.addQueue({ type: "emit", value: [event, payload] });
    }
  }
  removeEventsByEvent(events, callback) {
    try {
      for (const item of events) {
        this.log(`removing event listener for the event "${item}" at ${new Date(Date.now()).toISOString()}`);
        this.appEmitter.off(String(item), callback);
      }
    } catch (rawError) {
      const error = normalizeError(rawError);
      logger.error(new Error(`${this.logPrefix}, Can't unsubscribe ${error.message}`));
    }
  }
  runQueue() {
    const order = ["on", "once", "off", "emit"];
    const orderedQueue = this.queue.sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
    for (const item of orderedQueue) {
      if (item.type === "on") this.on(...item.value);
      if (item.type === "once") this.once(...item.value);
      if (item.type === "off") this.off(...item.value);
      if (item.type === "emit") this.emit(...item.value);
    }
    this.queue = [];
  }
  addQueue(item) {
    const items = item.value[0];
    if (items) {
      const events = Array.isArray(items) ? items : [items];
      for (const event of events) {
        this.log(`delay "${item.type}" handler for "${event}" event at ${new Date(Date.now()).toISOString()}`);
      }
    } else {
      this.log(`delay "${item.type}" handler at ${new Date(Date.now()).toISOString()}`);
    }
    this.queue.push(item);
  }
  log(...messages) {
    if (this.debug) {
      this.logger.log(this.logPrefix, ...messages);
    }
  }
}
let eventsBus;
function useEventsBus() {
  if (!eventsBus) {
    eventsBus = new EventsBus();
  }
  return eventsBus;
}
function getOptions(options) {
  if (typeof options === "object") return options;
  return {
    onDeactivatedDisabled: false,
    onBeforeMountDisabled: options
  };
}
function useBusSafeSubscribe(event, callback, skipDeactivationOrOptions = false) {
  if (process.env.VUE_APP_RENDERING_SSR) return { subscribe: noop, unsubscribe: noop };
  const {
    onBeforeMountDisabled,
    onDeactivatedDisabled
  } = getOptions(skipDeactivationOrOptions);
  const bus = useEventsBus();
  let isActive = false;
  const subscribe = () => {
    if (!isActive) {
      bus.on(event, callback);
      isActive = true;
    }
  };
  const unsubscribe = () => {
    if (isActive) {
      bus.off(event, callback);
      isActive = false;
    }
  };
  if (!onBeforeMountDisabled) {
    onBeforeMount(subscribe);
  }
  onActivated(subscribe);
  onBeforeUnmount(unsubscribe);
  if (!onDeactivatedDisabled) {
    onDeactivated(unsubscribe);
  }
  return {
    subscribe,
    unsubscribe
  };
}
export {
  BusEvent,
  EventsBus,
  useBusSafeSubscribe,
  useEventsBus
};
