import { useLocalStorageManager } from '@leon-hub/local-storage';

const deviceCustomerLoginKey = 'deviceCustomerLogin';

// TODO: create unified mechanism for sync storage data
const DeviceCustomerLoginStorage = {
  setLogin(value: string): void {
    useLocalStorageManager().setItem(deviceCustomerLoginKey, value);
  },
  remove(): void {
    useLocalStorageManager().removeItem(deviceCustomerLoginKey);
  },
  getLogin(): string | null {
    return useLocalStorageManager().getItem(deviceCustomerLoginKey);
  },
};

export default DeviceCustomerLoginStorage;
