var Color = /* @__PURE__ */ ((Color2) => {
  Color2["TEXT_DEFAULT"] = "text-default";
  Color2["BRAND_DEFAULT"] = "brand-default";
  return Color2;
})(Color || {});
var ColorToken = /* @__PURE__ */ ((ColorToken2) => {
  ColorToken2["TRANSPARENT"] = "transparent";
  ColorToken2["BUTTON_1"] = "var(--ColorButton1)";
  ColorToken2["BUTTON_1A"] = "var(--ColorButton1a)";
  ColorToken2["BUTTON_2"] = "var(--ColorButton2)";
  ColorToken2["BUTTON_2A"] = "var(--ColorButton2a)";
  ColorToken2["BUTTON_3"] = "var(--ColorButton3)";
  ColorToken2["BUTTON_3A"] = "var(--ColorButton3a)";
  ColorToken2["BUTTON_4"] = "var(--ColorButton4)";
  ColorToken2["BUTTON_4A"] = "var(--ColorButton4a)";
  ColorToken2["BACKGROUND_1"] = "var(--ColorBackground1)";
  ColorToken2["BACKGROUND_2"] = "var(--ColorBackground2)";
  ColorToken2["BACKGROUND_2A"] = "var(--ColorBackground2a)";
  ColorToken2["BACKGROUND_2B"] = "var(--ColorBackground2b)";
  ColorToken2["BACKGROUND_2C"] = "var(--ColorBackground2c)";
  ColorToken2["BACKGROUND_3"] = "var(--ColorBackground3)";
  ColorToken2["BACKGROUND_4"] = "var(--ColorBackground4)";
  ColorToken2["BACKGROUND_5"] = "var(--ColorBackground5)";
  ColorToken2["BACKGROUND_5A"] = "var(--ColorBackground5a)";
  ColorToken2["BACKGROUND_6"] = "var(--ColorBackground6)";
  ColorToken2["BACKGROUND_6A"] = "var(--ColorBackground6a)";
  ColorToken2["BACKGROUND_7"] = "var(--ColorBackground7)";
  ColorToken2["BACKGROUND_7A"] = "var(--ColorBackground7a)";
  ColorToken2["BACKGROUND_8"] = "var(--ColorBackground8)";
  ColorToken2["BACKGROUND_8A"] = "var(--ColorBackground8a)";
  ColorToken2["CONTENT_1"] = "var(--ColorContent1)";
  ColorToken2["CONTENT_1A"] = "var(--ColorContent1a)";
  ColorToken2["CONTENT_1B"] = "var(--ColorContent1b)";
  ColorToken2["CONTENT_1C"] = "var(--ColorContent1c)";
  ColorToken2["CONTENT_2"] = "var(--ColorContent2)";
  ColorToken2["CONTENT_2A"] = "var(--ColorContent2a)";
  ColorToken2["CONTENT_3"] = "var(--ColorContent3)";
  ColorToken2["CONTENT_4"] = "var(--ColorContent4)";
  ColorToken2["CONTENT_4A"] = "var(--ColorContent4a)";
  ColorToken2["CONTENT_4B"] = "var(--ColorContent4b)";
  ColorToken2["CONTENT_4C"] = "var(--ColorContent4c)";
  ColorToken2["CONTENT_5"] = "var(--ColorContent5)";
  return ColorToken2;
})(ColorToken || {});
export {
  Color,
  ColorToken
};
