<script lang="ts" setup>
import type {
  Slots,
  VNode,
} from 'vue';
import {
  computed,
  h,
  useSlots,
} from 'vue';

import { logger as loggerInstance } from '@leon-hub/logging';
import { Tag } from '@leon-hub/tags';

import type { TextSlotPlaceholderTag } from '../types';

interface TextSlotPlaceholderProps {
  tag?: TextSlotPlaceholderTag;
  text?: string;
}

const props = withDefaults(defineProps<TextSlotPlaceholderProps>(), {
  tag: Tag.SPAN,
  text: '',
});
const placeholderRegexp = /(\{[^\s{}]+\})/g;
const logger = loggerInstance.createNamespace('TextSlotPlaceholder');

function validateSlots(slots: Slots, text: string): Array<string | VNode> {
  if (Object.keys(slots).length <= 0) {
    return [text];
  }

  if (!text) {
    return [text];
  }

  const splits = text.split(placeholderRegexp);
  const hasPlaceholders = splits.some((item) => placeholderRegexp.test(item));

  if (!hasPlaceholders) {
    return [text];
  }

  return splits.reduce((accumulator: Array<string | VNode>, item) => {
    const isPlaceholder = placeholderRegexp.test(item);
    const slot = slots[item.slice(1, -1)];
    if (slot && isPlaceholder) {
      accumulator.push(...slot());
    } else {
      accumulator.push(item);
    }

    if (!slot && isPlaceholder) {
      logger.warn(`Slot with name: "${item}" not exists`);
    }

    return accumulator;
  }, []);
}

const slots: Slots = useSlots();

const component = computed(() => h(props.tag, validateSlots(slots, props.text)));
</script>

<template>
  <component v-auto-id="'TextSlotPlaceholder'"
    :is="component"
  />
</template>
