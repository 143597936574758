import type { Component, Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import {
  bannerTypeJackpot,
  bannerTypeSport,
  HomePageType,
  PathType,
} from '@leon-hub/api-sdk';
import { isSportlineRouteName, CasinoRouteName } from '@leon-hub/routing-config-names';

import type { VHeadlineBannerProps } from 'web/src/components/HeadlineBanner/VHeadlineBanner/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useBannerTexts } from 'web/src/modules/banners/components/composables';

import type { MainBannerItemProps } from '../types';

export interface MainBannerItemComposable {
  item: Ref<Component | null>;
  bannerProperties: Ref<VHeadlineBannerProps>;
}

export default function useMainBannerItem(props: RequiredField<MainBannerItemProps, 'aspectRatio'>): MainBannerItemComposable {
  const { homePageType } = useSiteConfigStore();
  const route = useRoute();

  const item = ref<Component | null>(null);

  const isInternalLink = computed<boolean>(() => props.banner.pathType === PathType.INTERNAL_PATH);
  const isDivContainer = computed<boolean>(() => props.banner.pathType === PathType.MARKETING_LEONSHOP);
  const isMiddleSizeBanner = computed<boolean>(
    () => !!process.env.VUE_APP_LAYOUT_DESKTOP
      && !!process.env.VUE_APP_FEATURE_CASINO_ENABLED
      && (
        (homePageType === HomePageType.SLOTS
        && !isSportlineRouteName(route.name))
        || (homePageType === HomePageType.SPORTS
        && (route.name === CasinoRouteName.CASINO_LOBBY || route.name === CasinoRouteName.CASINO_LIVE_LOBBY))
      )
      && props.banner.isNewBannerView,
  );
  const bannerContent = computed(() => props.banner.content);

  const {
    headline,
    secondaryText,
    buttonText,
  } = useBannerTexts(bannerContent);

  const bannerProperties = computed<VHeadlineBannerProps>(() => {
    const {
      aspectRatio,
      linkTitle,
      isRedirectDisabled,
      bannerType,
      isActive,
      isVisibleImage,
      isBigHeight,
      groupId,
      isHalfWidth,
    } = props;
    const mainImageComputed = isMiddleSizeBanner.value && props.banner.content.imageMiddle
      ? props.banner.content.imageMiddle : props.banner.content.image;
    const backgroundImageComputed = isMiddleSizeBanner.value && props.banner.content.bgImageMiddle
      ? props.banner.content.bgImageMiddle : (props.banner.content.bgImage ?? undefined);

    return {
      bannerId: props.banner.bannerId ?? '',
      groupId,
      mainImage: mainImageComputed,
      backgroundImage: backgroundImageComputed,
      backgroundColor: props.banner.content.bgColor || undefined,
      forceHideImage: props.isSlideHidden,
      animation: props.banner.content.animation ?? undefined,
      url: props.banner.content.url,
      isInternalLink: isInternalLink.value,
      headline: headline.value,
      secondaryText: secondaryText.value,
      buttonText: buttonText.value,
      linkTitle,
      aspectRatio,
      isRedirectDisabled,
      bannerType,
      isActive,
      isDivContainer: isDivContainer.value,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      isNT: props.isGroupBannersDev || props.banner.isNT,
      textColor: props.banner.textColor || undefined,
      isTextShadowHidden: props.banner.isTextShadowHidden || undefined,
      isVisibleImage,
      isBigHeight,
      isNewBannerView: props.banner.isNewBannerView || undefined,
      hasEventDetails: props.banner.bannerType === bannerTypeSport && !!props.banner.event,
      isHalfWidth,
      bannerVersion: props.banner.bannerVersion,
      isJackpot: props.banner.bannerType === bannerTypeJackpot,
      jackpotContent: props.banner.content.egsJackpot ?? undefined,
    };
  });

  return {
    item,
    bannerProperties,
  };
}
