import { computed, toRef } from 'vue';

import {
  Events as AnalyticsEvent,
} from '@leon-hub/yandex-metrika';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCountryStore } from 'web/src/modules/country/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import ServerDate from 'web/src/utils/ServerDate';

import type { ShareSuccessLogPayload } from '../submodules/slip-share/types';
import { useSlipInfoStore } from '../submodules/slip-info/store';

interface UseShareEventLogging {
  logShareSuccess(payload: ShareSuccessLogPayload): void;
  logLinkCopied(payload: ShareSuccessLogPayload): void;
  logUnableToShare(message: string): void;
  logGetSharedCouponSuccess(shareCode: string): void;
  logGetSharedCouponError(shareCode: string, message: string): void;
}

/**
 * LEONWEB-14699
 * https://docs.google.com/document/d/11mZcLO99c-1jUNtVDGZECrtyZMBCAHMNkTpzxkpLZOc/edit?usp=sharing
 */

type LoggedStatus = 'logged_in' | 'logged_out';

interface CommonLogData {
  customer_id: string;
  customer_status: LoggedStatus;
  vs_lw: boolean;
  full_url: string; // 'https://3956.fun/' or any site mirror with Page Path
  country: string; // country code;
}

interface SuccessLogData {
  bet_type: string; // 'single' or system, or any other relevant value
  bet_count: number; // number of events included in the coupon
  bet_total_odd: string; // '6.57' or '2 | 1.2 | 3.3' for multisingles,
  bet_share_code: string;
}

export function useShareEventLogging(): UseShareEventLogging {
  const { pushGTM } = useAnalytics();

  const slipInfoStore = useSlipInfoStore();
  const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
  const allOdds = toRef(slipInfoStore, 'allOdds');
  const betMode = toRef(slipInfoStore, 'betMode');

  const { isLoggedIn } = useIsLoggedIn();

  const customerDataStore = useCustomerDataStore();

  const customerId = toRef(customerDataStore, 'login');
  const isVip = toRef(customerDataStore, 'isVip');
  const customerCountry = toRef(customerDataStore, 'country');

  const countryCode = toRef(useCountryStore(), 'countryCode');

  const getCommonLogData = (): CommonLogData => ({
    customer_id: customerId.value,
    customer_status: isLoggedIn.value ? 'logged_in' : 'logged_out',
    vs_lw: isVip.value,
    full_url: document.location.href,
    country: customerCountry.value || countryCode.value,
  });

  const betType = computed<string>(() => betMode.value.toLowerCase());

  const getSuccessPayload = (payload: ShareSuccessLogPayload): SuccessLogData => ({
    // LEONWEB-15173 - dat for the moment share button was pressed, related to generated image
    bet_type: payload.betMode.toLowerCase(),
    bet_count: payload.eventsCount,
    bet_total_odd: payload.odds,
    bet_share_code: payload.shareCode ?? '',
  });

  const logShareSuccess = (payload: ShareSuccessLogPayload): void => {
    pushGTM(AnalyticsEvent.BET_SHARE, {
      ...getCommonLogData(),
      ...getSuccessPayload(payload),
      bet_share_timestamp: ServerDate.now(),
    });
  };

  const logLinkCopied = (payload: ShareSuccessLogPayload): void => {
    pushGTM(AnalyticsEvent.BET_SHARE_COPY_LINK, {
      ...getCommonLogData(),
      ...getSuccessPayload(payload),
      bet_copy_timestamp: ServerDate.now(),
    });
  };

  const logUnableToShare = (message: string): void => {
    pushGTM(AnalyticsEvent.BET_SHARE_ERROR, {
      ...getCommonLogData(),
      bet_type: betType.value,
      bet_count: slipEventsCount.value,
      bet_share_error_message: message,
      bet_share_error_timestamp: ServerDate.now(),
    });
  };

  const logGetSharedCouponSuccess = (shareCode: string): void => {
    pushGTM(AnalyticsEvent.SHARED_BET_LOAD, {
      ...getCommonLogData(),
      bet_type: betType.value,
      bet_count: slipEventsCount.value,
      bet_total_odd: allOdds.value.join(' | '),
      bet_share_code: shareCode,
      bet_load_timestamp: ServerDate.now(),
    });
  };

  const logGetSharedCouponError = (shareCode: string, message: string): void => {
    pushGTM(AnalyticsEvent.SHARED_BET_ERROR, {
      ...getCommonLogData(),
      bet_share_code: shareCode,
      bet_load_error_message: message,
      bet_load_error_timestamp: ServerDate.now(),
    });
  };

  return {
    logShareSuccess,
    logLinkCopied,
    logUnableToShare,
    logGetSharedCouponSuccess,
    logGetSharedCouponError,
  };
}
