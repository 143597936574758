import { useLocalStorageManager } from '@leon-hub/local-storage';
import { logger } from '@leon-hub/logging';

import type {
  SlipSnapshotForLocalStorage,
} from '../types';

interface UseSlipLocalStorage {
  saveSnapshotToLocalStorage(snapshot: SlipSnapshotForLocalStorage): void;
  clearLocalStorageState(): void;
  getSnapshotFromLocalStorage(): SlipSnapshotForLocalStorage | null;
}

export default function useSlipLocalStorage(): UseSlipLocalStorage {
  const localStorageManager = useLocalStorageManager();
  const localStorageKey = 'web2_slip';

  const clearLocalStorageState = (): void => {
    try {
      localStorageManager.removeItem(localStorageKey);
    } catch (error) {
      logger.error('clearLocalStorageState error', error);
    }
  };

  const saveSnapshotToLocalStorage = (snapshot: SlipSnapshotForLocalStorage): void => {
    const asString = JSON.stringify(snapshot);
    if (asString) {
      localStorageManager.setItem(localStorageKey, asString);
    }
  };

  const getSnapshotFromLocalStorage = (): SlipSnapshotForLocalStorage | null => {
    try {
      const saveStateSnapshotString = localStorageManager.getItem(localStorageKey);
      if (!saveStateSnapshotString) {
        return null;
      }
      const parsedSnapshot = JSON.parse(saveStateSnapshotString) as SlipSnapshotForLocalStorage;
      return parsedSnapshot || null;
    } catch {
      return null;
    }
  };

  return {
    clearLocalStorageState,
    saveSnapshotToLocalStorage,
    getSnapshotFromLocalStorage,
  };
}
